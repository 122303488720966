<template>
  <div id="cart" class="container">
    <loading :show="submitLoading" />
    <template v-if="cart.total">
      <div class="cart-box">
        <div class="item" v-for="item in carts2" :key="item.spuId">
          <div class="close" @click="removeCartList(item)">
            <i class="iconfont icon-remove" title="Remove"></i>
          </div>
          <div class="image" :style="{ backgroundImage: `url(${item.cover})` }"></div>
          <div class="flex1">
            <div class="name">
              <router-link :to="`/${item.spuId}`">
                {{ item.spuName | zxby }}
              </router-link>
            </div>
            <div class="price-box">
              <div class="price">€{{ item.price }}</div>
              <!--<div class="price" v-if="item.num<text_num">€{{ item.price }}</div>-->
              <!--<div class="price" v-if="item.num>=text_num">€{{ text_price}}</div>-->
              <div class="cart-plus-minus">
                <input @change="quantityChange(item, -1)" class="cart-plus-minus-box" disabled v-model="item.num" type="text" />
                <div @click="quantityChange(item, 0)" class="dec qtybutton">
                  <i class="iconfont icon-down"></i>
                </div>
                <div @click="quantityChange(item, 1)" class="inc qtybutton">
                  <i class="iconfont icon-up"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-box">
        <table class="table">
          <thead>
            <tr>
              <th class="torress-product-remove">
                {{ $i18n.ShopCart.th.remove }}
              </th>
              <th class="torress-product-thumbnail">
                {{ $i18n.ShopCart.th.images }}
              </th>
              <th class="cart-product-name">{{ $i18n.ShopCart.th.Product }}</th>
              <th class="torress-product-price">
                {{ $i18n.ShopCart.th.UnitPrice }}
              </th>
              <th class="torress-product-quantity">
                {{ $i18n.ShopCart.th.Quantity }}
              </th>
              <th class="torress-product-subtotal">
                {{ $i18n.ShopCart.th.Total }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in carts2" :key="item.spuId">
              <td class="torress-product-remove">
                <a @click="removeCartList(item)"><i class="iconfont icon-remove" title="Remove"></i></a>
              </td>
              <td class="torress-product-thumbnail">
                <a href="javascript:void(0)">
                  <img :src="item.cover" :alt="item.spuName" />
                </a>
              </td>
              <td class="torress-product-name">
                <router-link :to="`/${item.spuId}`">
                  <a href="javascript:void(0)">{{ item.spuName | zxby }}</a>
                </router-link>
              </td>
              <td class="torress-product-price">
                <span class="amount">€{{ item.price }}</span>
                <!--<span class="amount"  v-if="item.num<text_num">€{{ item.price }}</span>-->
                <!--<span class="amount"  v-if="item.num>=text_num">€{{ text_price}}</span>-->
              </td>
              <td class="quantity">
                <!-- <label>Quantity</label> -->
                <div class="cart-plus-minus">
                  <input @change="quantityChange(item, -1)" class="cart-plus-minus-box" disabled v-model="item.num"
                    type="text" />
                  <div @click="quantityChange(item, 0)" class="dec qtybutton">
                    <i class="iconfont icon-down"></i>
                  </div>
                  <div @click="quantityChange(item, 1)" class="inc qtybutton">
                    <i class="iconfont icon-up"></i>
                  </div>
                </div>
              </td>
              <td class="product-subtotal">
                <span class="amount">€{{ (item.price * item.num).toFixed(2) }}</span>
                <!--<span class="amount" v-if="item.num<text_num">€{{ (item.price * item.num).toFixed(2) }}</span>-->
                <!--<span class="amount" v-if="item.num>=text_num">€{{ (text_price * item.num).toFixed(2) }}</span>-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="address-box" v-if="token">
        <div class="address-list-box">
          <div class="addnew" @click="addAddressVisible = true">
            {{ $i18n.ShopCart.ADD_ADDRESS }}
          </div>
          <div class="addnewtitle">{{ $i18n.Order.ConsigneeInformation }}</div>
        </div>
        <div class="list">
          <div v-if="address.length < 1">
            {{ $i18n.ShopCart.TipEmptyAddress
            }}<span style="cursor: pointer; color: #fed100" @click="addAddressVisible = true">{{
              $i18n.ShopCart.AddText
            }}</span>
          </div>
          <table class="addTable">
            <tr>
              <td class="first">
                Receptor
              </td>
              <td>Dirección de Envío</td>
              <td>número de contacto</td>
              <td>defecto</td>
            </tr>
            <tr v-for="item in address" :key="item.addressId">
              <td class="first">
                <div :class="{ check: addressCheckedId == item.addressId }" @click="changeAddress(item.addressId)">
                  {{ item.man }}
                  <i class="ion-checkmark" v-if="addressCheckedId == item.addressId"></i>
                </div>
              </td>
              <td>
                <div>{{ item.address }}</div>
              </td>
              <td>
                <div>{{ item.phone }}</div>
              </td>
              <td>
                <div style="cursor: pointer;" @click="handleDeleteAddress(item.addressId)">
                  Eliminar
                </div>
                <!-- <div :class="{ 'default-add': item.defaultFlag == 1 }">
                  {{ item.defaultFlag == 1 ? 'Default address' : 'ess' }}
                </div> -->
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div style="text-align: right; color: red">Minimo pedido {{ prices.minOrderAmount }} Euro</div>
      <!-- <div style="margin-top: 20px">
        <div style="float:  right">
          <div style=" color: #333; margin-bottom:20px" v-if="prepare.coupons">Cupones({{prepare.coupons.length}})</div>
          <div style=" color: #333; margin-bottom:20px" v-else>Cupones(0)</div>
          <el-select v-model="coupon" placeholder="Please select coupons" @change="selectChanged">
            <el-option v-for="item in prepare.coupons" :key="item.couponUserId" :label="item.couponName"
              :value="item.couponUserId"> </el-option>
          </el-select>
        </div>
      </div> -->
      <div class="row row_box" style="margin-top: 80px">
        <div class="col-md-5 ml-auto">
          <div class="cart-page-total">
            <loading :show="renewLoading" />
            <h2>{{ $i18n.ShopCart.TotalText }}</h2>
            <ul>
              <li>
                {{ $i18n.ShopCart.Subtotal }}
                <span>€{{ prices.totalFee }}</span>
              </li>
              <li v-if="prices.expressFee">
                {{ $i18n.ShopCart.Express }}
                <span>€{{ prices.expressFee }}</span>
              </li>
              <!-- <li>
                {{ $i18n.ShopCart.Cupones }}
                <span>€{{ totalDiscount }}</span>
              </li> -->
              <li>
                {{ $i18n.ShopCart.Total }}
                <span>€{{ (prices.totalAmount*10-totalDiscount*10)/10 }}</span>
              </li>
            </ul>
            <a href="javascript:void(0)" @click="submit">{{ $i18n.ShopCart.Checkout }}</a>
          </div>
        </div>
      </div>
    </template>
    <div v-else style="min-height: 300px; ">
      {{ $i18n.ShopCart.EmptyCartText
      }}<router-link style="color: #fed100" to="/classify">{{ $i18n.ShopCart.BuyText }}</router-link>
    </div>
    <add-address-dom :show="addAddressVisible" @addressOk="getAddress" />
  </div>
</template>
<script>
  import Vue from 'vue'
  import {
    Select,
    Option
  } from 'element-ui'
  Vue.use(Select)
  Vue.use(Option)
  import loading from '@/views/public/loading.vue'
  import addAddressDom from '@/views/public/addAddress.vue'
  import {
    renewCart,
    getAddress,
    orderCreate,
    orderPrepare,
    deleteAddress,
    setCartUpdate
  } from '@/api'
  import {
    mapState,
    mapGetters
  } from 'vuex'

  export default {
    data() {
      return {
        address: [],
        prepare: {},
        addressChecked: 0, //手动选中的地址ID
        // carts2: [],
        timeoutLoading: false, //是否在更新中
        submitLoading: false, //提交订单状态
        coupon: "",
        options: [

        ],
        totalDiscount: '0',
        addAddressVisible: true, //添加地址框是否显示，
        text_num:10,//测试数据
        text_price:10//测试数据

      }
    },
    computed: {

      ...mapState(['cart', 'cartLoading']),
      ...mapGetters(['carts', 'token']),
      renewLoading() {
        const str = this.cartLoading && this.timeoutLoading
        return str
      },
      prices() {

        const {
          totalAmount,
          expressFee,
          minOrderAmount
        } = this.cart

        const prepare = this.prepare;
        let data = {}
        let cartTotal = {
          totalAmount: totalAmount,
          totalFee: totalAmount,
          expressFee: expressFee || '0.00',
          minOrderAmount: minOrderAmount,
          expressFee
        }
        if (prepare && prepare.totalAmount) {
          //   // const { totalAmount, totalFee, expressFee } = prepare
          //   // data = { totalAmount, totalFee, expressFee }
          return {
            ...cartTotal,
            ...prepare
          }
        }
        return cartTotal
      },
      carts2() {
          console.log('this.carts',this.carts)
        return this.carts
      },
      addressCheckedId() {
        let data = this.address.filter(e => e.defaultFlag == 1)
        if (data) {
          data = data[0] || {}
        } else {
          data = {}
        }
        let id = this.addressChecked || data.addressId
        if (!id && this.address) {
          const item = this.address[0]
          id = item && item.addressId
        }
        return id || ''
      }
    },
    watch: {
      addressCheckedId(v) {
        this.handlePerpare(v)
      },
      token() {
        this.getAddress(true)
      }
    },
    components: {
      loading,
      addAddressDom
    },
    created() {
      if (this.token) {
        this.$store.dispatch('setAppLoading', true)
        this.getAddress(true)
      }
    },
    methods: {
      selectChanged(data) {
        if (data) {
          let a = []
          a = this.prepare.coupons.filter(ele => ele.couponUserId == data)
          this.totalDiscount = a[0].amount;

        }
      },
      handleDeleteAddress(id) {
        this.$confirm('¿Estás seguro de que deseas eliminar el producto?', 'rápido', {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'error'
        }).then(_ => {
          deleteAddress({
            addressId: id
          }).then(res => {
            if (res.data.status !== 'fail') {
              this.$toasted.success(this.$i18n.Address.DELETE_SUCCESS)
              this.address = this.address.filter(e => e.addressId != id)
            } else {
              this.$toasted.error(res.data.msg || this.$i18n.Address.DELETE_SUCCESS)
            }
          })
        })
      },
      handlePerpare(v) {
        if (!this.carts2 && !Array.isArray(this.carts2)) {
          return
        }
        let carts = this.carts2.map(e => e.cartId)
        let params = {
          carts: JSON.stringify(carts),
          addressId: v
        }
        orderPrepare(params).then(res => {
          if (res.data.status === 'SUCCESSS') {
            this.prepare = res.data.data
          } else {
            return this.$toasted.error(res.data.msg || this.$i18n.ShopCart.ADD_ERROR)
          }
          // 2. 添加订单信息
        })
      },
      submit() {
        if (!this.addressCheckedId) {
          return this.$toasted.error(this.$i18n.ShopCart.SelectAddressText)
        }
        this.submitLoading = true

        // 不用计算了，1. 计算订单信息 /order/prepare
        let carts = this.carts2.map(e => e.cartId)
        let params = {
          carts: JSON.stringify(carts),
          addressId: this.addressCheckedId,
          couponUserId: this.coupon
        }
        orderCreate(params).then(res => {
          if (res.data.status === 'SUCCESSS') {
            // 添加成功，页面跳转
            this.$toasted.success(this.$i18n.ShopCart.ADD_SUCCESS)

            setTimeout(() => {
              this.submitLoading = false
              // 清除购物车内容
              this.$store.commit('SET_CART')
              this.$router.replace('/order/' + res.data.data.orderId)
            }, 1500)
          } else {
            this.submitLoading = false

            return this.$toasted.error(res.data.msg || this.$i18n.ShopCart.ADD_ERROR)
          }
          // 2. 添加订单信息
        })
      },
      changeAddress(id) {
        this.addressChecked = id
      },
      getAddress(data) {
        this.addAddressVisible = false
        if (!data) {
          return
        }
        getAddress({}).then(res => {
          this.address = res.data.data || []
          this.$store.dispatch('setAppLoading', false);

        })
      },

      /* removeCartList(item) {
        let cart = this.carts2.filter(e => e.spuId != item.spuId)
        this.formatCartParams(cart)
      },*/
      removeCartList(item) {
        // if (!confirm('¿Estás seguro de que deseas eliminar el producto?')) {
        //   return
        // }

        this.$confirm('¿Estás seguro de que deseas eliminar el producto?', 'Eliminar', {
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            type: 'error'
          })
          .then(() => {
            // let cart = this.carts2.filter(e => e.spuId != item.spuId)
            // this.formatCartParams(cart)

            let params = {
              sku_id: item.skuId,
            }
            if (item.isWholesale == 1) {
              params.batch_num = 0
            }else{
              params.num = 0
            }
            /* if (this.list.skuId) {
              params.sku_id = item.spuId
            } else {
              if (this.list.skus[0]) {
                params.sku_id = this.list.skus[0].skuId
              }
            }*/

            setCartUpdate(params).then(res => {
              if (res.data.data) {
                this.$store.dispatch('onChangeCart')
              } else {
                this.$toasted.error(res.data.msg)
              }
              setTimeout(() => {
                this.loading = false
              }, 500)
            })
          })
          .catch(() => {})
      },
      formatCartParams(cart) {
        // [{"cart_id":"2396","sku_id":"item.sku_id","num":3}]
        
        let params = []
        for (var i = cart.length - 1; i >= 0; i--) {
          params.push({
            cart_id: cart[i].cartId,
            sku_id: cart[i].spuId,
            num: cart[i].num
          })
        }

        // this.cart = cart
        renewCart(params).then(res => {
          if (res.data.status === 'fail') {
            this.$toasted.error(res.data.msg)
            this.$store.dispatch('cartLoading', false)
            // 刷新
          } else {
            if (this.addressCheckedId) {
              setTimeout(() => {
                this.handlePerpare(this.addressCheckedId)
              }, 2000);
              
            }
            this.$store.dispatch('onChangeCart', this.addressCheckedId)
          }
        })
      },
      quantityChange(item, data) {
          console.log('测试一下',item, data)
        /*思路
        1.先修改数量。
        然后settimeout添加到服务器，renecart.
        2.添加的时候付款按钮不可选
        */
        // if (data && item.stock * 1 < item.num * 1) {
        //   item.num = item.stock
        //   // return this.$toasted.error(this.$i18n.NO_STOCK)
        // }
        if (item.isWholesale == '1') {
          item.num = Number(item.num)
          item.wholesaleNum = Number(item.wholesaleNum)
          let max = item.stock
          if (data) {
            if (item.num < max) {
              if (item.num == 1) {
                item.num = 0
              }
              item.num=item.wholesaleNum+ item.num
            }
          } else {
            let q = item.num - item.wholesaleNum
            if (q < 1) {
              q = 0
            }
            item.num = q
          }
        } else {
          if (data != -1) {
            if (data) {
                item.num++
              } else {
                if (item <= 1) {
                  return false
                }
                item.num--
              }
            }

            if (item.num < 1) {
              // this.$toasted.error('Se debe comprar al menos 1 pieza！')
              item.num = 0
            }
        }
        

        if (data && item.stock * 1 < item.num * 1) {
          item.num = item.stock
          this.$toasted.error(this.$i18n.NO_STOCK)
        }

        this.timeoutLoading = true;
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.formatCartParams(this.carts2)

        }, 0)
        return
      }
    }
  }

</script>
<style lang="less">
  @border-color: #ebebeb;
  @body-text_color: #333333;
  @primary-color: #fed100;
  @bg-white_color: #fff;

  #cart {
    .table-box {
      overflow-x: auto;
    }

    .cart-box {
      .flex1 {
        flex: 1;
      }

      .price-box {
        display: flex;
        align-items: center;

        .price {
          flex: 1;
          color: red;
        }
      }

      >.item {
        margin-bottom: 10px;
        padding-bottom: 10px;
        display: flex;
        position: relative;
        border-bottom: 1px solid #e6e6e6;

        .close {
          // position: absolute;
          padding: 10px 10px 10px 5px;
          align-self: center;
        }

        .image {
          width: 100px;
          margin-right: 10px;
          background-size: cover;
          height: 100px;
          background-color: #efefef;
          background-position: center;
          border-radius: 4px;
        }

        .name {
          font-size: 18px;
        }
      }
    }

    position: relative;

    .address-box {
      margin-bottom: 5px;
      position: relative;

      .address-list-box {
        margin: 50px 0 20px 0;
      }

      .first {
        div {
          border: 3px solid transparent;
          cursor: pointer;

          &.check {
            border-color: #fed100;
          }
        }
      }

      .addnew {
        margin-left: 30px;
        cursor: pointer;
        float: right;
        background-color: #333333;
        color: #fff;
        padding: 10px 20px;
        line-height: 1;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #fed100;
        }
      }

      .name {
        margin-bottom: 5px;
        line-height: 1;
      }

      .title {
        font-weight: bold;
      }
    }

    .loading {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.75);
    }

    .torress-product-thumbnail {
      img {
        max-width: 120px;
      }
    }
    .row_box{
      width: 100%;
    }
    table {
      background: @bg-white_color none repeat scroll 0 0;
      border-color: @border-color;
      border-radius: 0;
      border-style: solid;
      border-width: 1px 0 0 1px;
      text-align: center;
      width: 100%;
      margin-bottom: 0;

      th {
        border-top: medium none;
        font-size: 15px;
        text-transform: capitalize;
        padding: 20px 10px;
        text-align: center;
        font-weight: 500;
        vertical-align: middle;
      }

      td {
        border-top: medium none;
        padding: 18px 10px;
        vertical-align: middle;
        font-size: 13px;

        &.torress-product-price,
        &.product-subtotal {
          font-size: 16px;
          font-weight: 700;
        }

        &.torress-product-name {
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
        }

        img {
          display: inline-block;
        }
      }

      th,
      td {
        border-bottom: 1px solid @border-color;
        border-right: 1px solid @border-color;
      }
    }

    .cart-page-total {
      padding-top: 50px;
      position: relative;

      .loading {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.6);
      }

      >h2 {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 20px;
        text-transform: capitalize;
      }

      >ul {
        border: 1px solid @border-color;

        li {
          list-style: none;
          font-size: 15px;
          padding: 10px 30px;
          border-bottom: 1px solid @border-color;
          font-weight: 700;

          &:last-child {
            border-bottom: 0;
          }

          span {
            float: right;
          }
        }
      }

      a {
        background-color: @body-text_color;
        border: 1px solid @border-color;
        color: @bg-white_color;
        display: inline-block;
        margin-top: 30px;
        padding: 10px 20px;
        text-transform: capitalize;

        &:hover {
          background: @primary-color;
          border-color: @primary-color;
          color: @bg-white_color  !important;
        }
      }
    }
  }

</style>
